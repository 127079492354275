body {
  font-size: 16px;
  line-height: 2;
  margin: 0;
  padding: 0 0 0 0;
  background-color: #fbfbfb; }
  body .bg-light {
    background-color: transparent !important; }
  body div.content {
    padding-top: 80px;
    padding-bottom: 120px; }
  body footer {
    padding: 50px 0 80px 0; }
  body h1 {
    padding-bottom: 30px; }
  body .project-images {
    margin-top: 30px; }
  body .project-image {
    margin-top: 30px; }
  body .container.article img {
    width: 100%; }
  body div.card {
    background-color: transparent; }
    body div.card div.card-title {
      text-align: center; }
    body div.card img {
      /*
      height: 270px;
      object-fit: cover;
      */ }
  body .videoWrapper {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    padding-top: 25px;
    height: 0; }
    body .videoWrapper iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
